import 'external-svg-loader';

import '@cm/styles/images/logos-customer.svg';

function loadExternalSvgSprites(paths: string[]) {
  paths.forEach((path) => {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttributeNS(null, 'data-src', path);
    svg.setAttributeNS(null, 'data-unique-ids', 'disabled');
    svg.setAttributeNS(null, 'style', 'display:none;visibility:hidden');
    document.body.appendChild(svg);
  });
}

(window as any).loadExternalSvgSprites = loadExternalSvgSprites;
